import styled from 'styled-components'

import { Box } from '@material-ui/core'
import media from '../utils/media'

const FullWidthSection = styled(Box)`
  padding: 40px 0 20px;
  width: 100%;

  ${media.sm`
    padding-top: 60px;
  `}

  ${media.md`
    padding-top: 100px;
  `}
`

export default FullWidthSection
