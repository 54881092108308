import React from 'react'

import { useStaticQuery, graphql, navigate } from 'gatsby'
import styled from 'styled-components'

import { Box, Grid, Hidden, Paper, Typography } from '@material-ui/core'
import Img from 'gatsby-image'
import HeroSlider, { Nav, Slide } from 'hero-slider'

import materialUiTheme, { fonts } from '../material-ui-theme'

import ContainedSection from '../components/ContainedSection'
import Fab from '../components/Fab'
import FullScreenSection from '../components/FullScreenSection'
import FullWidthSection from '../components/FullWidthSection'
import HomeLayout from '../components/HomeLayout'
import SpecialtiesCarousel from '../components/SpecialtiesCarousel'
import YoutubeBackground from '../components/YoutubeBackground'
import SEO from '../components/seo'

import media from '../utils/media'
import {
  Fade,
  Flip,
  Slide as SlideAnimation,
  Zoom,
} from 'react-reveal'

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      maEstelaFullBodyImage: file(
        relativePath: { eq: "ma-estela-full-body.png" }
      ) {
        childImageSharp {
          fluid(maxHeight: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      maBinondoMamiImage: file(
        relativePath: { eq: "menu/mami/ma-binondo-mami.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 424) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      beefMamiImage: file(relativePath: { eq: "menu/mami/beef-mami.png" }) {
        childImageSharp {
          fluid(maxWidth: 424) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      beefWantonMamiImage: file(
        relativePath: { eq: "menu/mami/beef-wanton-mami.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 424) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      maChickenMamiSImage: file(
        relativePath: { eq: "menu/mami/ma-chicken-mami-s.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 424) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      maChickenWantonMamiImage: file(
        relativePath: { eq: "menu/mami/ma-chicken-wanton-mami.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 424) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      siomaiMamiSImage: file(
        relativePath: { eq: "menu/mami/siomai-mami-s.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 424) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      homeSlider1Image: file(relativePath: { eq: "home/hero-slider-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2560) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  /**
   * ! Duplicate of `menuData` in `menu.js`
   */
  const ourSpecialtiesData = [
    {
      id: 1,
      imageSrc: data.beefMamiImage.childImageSharp.fluid,
      description: `A bowl of freshly cooked noodles in warm flavorful soup topped with our signature Nanking Beef.`,
      title: `Beef Mami`,
    },
    {
      id: 2,
      imageSrc: data.siomaiMamiSImage.childImageSharp.fluid,
      description: `One of the most popular dimsums, Pork Siomai, served over freshly cooked noodles and hot rich stock.`,
      title: 'Siomai Mami',
    },
    {
      id: 4,
      imageSrc: data.maChickenWantonMamiImage.childImageSharp.fluid,
      description: `Freshly cooked wanton and chicken meat in a bowl of noodles and warm flavorful broth.`,
      title: `Ma's Chicken Wanton Mami`,
    },
    {
      id: 3,
      imageSrc: data.maChickenMamiSImage.childImageSharp.fluid,
      description: `A bowl of freshly cooked meaty chicken slices in our special noodle soup.`,
      title: `Ma's Chicken Mami`,
    },
    {
      id: 5,
      imageSrc: data.beefWantonMamiImage.childImageSharp.fluid,
      description: `A combination of tender beef brisket and pork dumplings drenched in a bowl of noodles and hot beef stock.`,
      title: 'Beef Wanton Mami',
    },
  ]

  return (
    <HomeLayout>
      <SEO title="Home" />
      <FullScreenSection>
        <HeroSlider
          orientation="horizontal"
          style={{
            alignItems: 'center',
            backgroundColor: '#000',
            display: 'flex',
            justifyContent: 'center',
          }}
          settings={{
            slidingDuration: 500,
            slidingDelay: 100,
            shouldAutoplay: true,
            shouldDisplayButtons: true,
            autoplayDuration: 16000,
            color: '#FFF',
            height: '100%',
          }}
        >
          <Slide
            background={{
              backgroundAttachment: 'fixed',
              backgroundImage: data.homeSlider1Image.childImageSharp.fluid.src,
              backgroundAnimation: 'zoom',
            }}
            onBackgroundLoad={() => {
              document.getElementById('loader').style.display = 'none';
              document.getElementsByTagName('body')[0].style.overflow = 'unset'
            }}
          >
            <StyledHeroSliderOverlay />
            <StyledSlideContentContainer>
              <Zoom>
                <StyledSlideContentTitle>
                 Come home to Ma
                </StyledSlideContentTitle>
              </Zoom>
            </StyledSlideContentContainer>
          </Slide>
          <Slide>
            <YoutubeBackground
              childrenContainerStyle={{
                height: '100%',
                width: '100%',
              }}
              // ! sets host to https://www.youtube-nocookie.com to avoid loading Google's cookies
              nocookie
              overlay="rgba(0, 0, 0, .3)"
              videoId="4juup-jvRZ8"
              style={{
                height: '100%',
                width: '100%',
              }}
            />
          </Slide>
          <Nav />
        </HeroSlider>
      </FullScreenSection>
      <ContainedSection>
        <Grid container>
          <Hidden xsDown>
            <Grid item md={2} xs={false}></Grid>
            <Grid item md={3} sm={4} xs={false}>
              <SlideAnimation left>
                <S.MaEstellaFullBodyImageWrapper>
                  <Img
                    fluid={data.maEstelaFullBodyImage.childImageSharp.fluid}
                  />
                </S.MaEstellaFullBodyImageWrapper>
              </SlideAnimation>
            </Grid>
          </Hidden>
          <Grid item md={5} sm={8} xs={12}>
            <Zoom>
              <Box color="secondary.dark">
                <OurStoryHeading>Our Story</OurStoryHeading>
              </Box>
            </Zoom>
            <Fade right>
              <Typography
                gutterBottom
                component="p"
                variant="subtitle1"
                style={{ marginBottom: '1em' }}
              >
                Inspired through the comforts of a loving mother, a post-war
                restaurant was established to give customers a reminder of a
                heart-felt meal that their mothers would usually prepare.
                Unconditional love unites the bond between a mother and child;
                hence the word Ma, Chinese for mother, came to its existence.
              </Typography>
            </Fade>
            <Fade right>
              <Typography
                gutterBottom
                component="p"
                variant="subtitle1"
                style={{ marginBottom: '2em' }}
              >
                The heart of Ma Chicken Mami House is from a dedicated and
                passionate mother of four, Estela. Right after the war, her
                inspiration to prepare dishes were the children of the war and
                the childhood that was taken away from them. She sought for ways
                to express her compassion which resulted to cooking for families
                and friends.
              </Typography>
            </Fade>
            <Fade top>
              <Fab
                color="secondary"
                size="large"
                variant="extended"
                onClick={() => navigate('/about-us')}
              >
                Read More
              </Fab>
            </Fade>
          </Grid>
          <Grid item md={1} xs={false}></Grid>
        </Grid>
      </ContainedSection>
      <ContainedSection>
        <Grid container>
          <Grid item xs={12} md={10}>
            <StyledSpecialMenuContainer>
              <SlideAnimation right>
                <Box>
                  <StyledFloatingImageContainer>
                    <Img
                      fluid={data.maBinondoMamiImage.childImageSharp.fluid}
                    />
                  </StyledFloatingImageContainer>
                </Box>
              </SlideAnimation>
              <StyledSpecialMenuPaper>
                <Zoom>
                  <Box color="secondary.dark">
                    <Typography gutterBottom variant="h4">
                      MA's Binondo Mami
                    </Typography>
                  </Box>
                </Zoom>
                <Fade left>
                  <Typography gutterBottom>
                    A bowl of rich noodle soup topped with chicken slices, pork
                    siomai, and roasted pork garnished with Chinese cabbage and
                    hard-boiled egg.
                  </Typography>
                </Fade>
              </StyledSpecialMenuPaper>
            </StyledSpecialMenuContainer>
          </Grid>
          <Hidden mdDown>
            <Grid item md={2}></Grid>
          </Hidden>
        </Grid>
      </ContainedSection>
      <FullWidthSection style={{ paddingTop: materialUiTheme.spacing(5) }}>
        <YoutubeBackground
          // ! sets host to https://www.youtube-nocookie.com to avoid loading Google's cookies
          nocookie
          overlay="rgba(0, 0, 0, .4)"
          videoId="h3xxpJ4cre4"
        >
          <Grid
            container
            alignItems="center"
            justify="center"
            style={{
              height: 480,
              paddingLeft: materialUiTheme.spacing(5),
              paddingRight: materialUiTheme.spacing(5),
            }}
          >
            <Grid item>
              <Box color="common.white">
                <Zoom>
                  <Typography
                    gutterBottom
                    variant="h3"
                    style={{
                      fontFamily: fonts['Brush455BT'],
                      textAlign: 'center',
                    }}
                  >
                    The way Mami should be
                  </Typography>
                </Zoom>
              </Box>
            </Grid>
          </Grid>
        </YoutubeBackground>
      </FullWidthSection>
      <ContainedSection>
        <Box
          alignItems="center"
          color="primary.light"
          display="flex"
          justifyContent="center"
          textAlign="center"
          marginBottom={8}
        >
          <Zoom>
            <Box bgcolor="primary.light" height="1px" mr={2} width="40px" />
            <S.OurSpecialtiesSectionTitle>
              Our Specialties
            </S.OurSpecialtiesSectionTitle>
            <Box bgcolor="primary.light" height="1px" ml={2} width="40px" />
          </Zoom>
        </Box>
        <Hidden smDown>
          <Grid
            container
            style={{
              flexWrap: 'nowrap',
              marginBottom: materialUiTheme.spacing(10),
            }}
          >
            {ourSpecialtiesData.map(
              ({ id, imageSrc, description, title }, index) => {
                const isLastItem = index === ourSpecialtiesData.length - 1
                const isEven = index % 2 === 0

                return (
                  <Grid
                    item
                    key={id}
                    style={{
                      flex: 1,
                      marginRight: !isLastItem ? materialUiTheme.spacing(3) : 0,
                      marginTop:
                        index === 1
                          ? materialUiTheme.spacing(10)
                          : index === 3
                          ? materialUiTheme.spacing(13)
                          : 0,
                    }}
                  >
                    <SlideAnimation top={isEven} bottom={!isEven}>
                      <Box>
                        <Box maxWidth="200px" mb={-11} mx="auto">
                          <Img fluid={imageSrc} />
                        </Box>
                        <Paper
                          style={{
                            backgroundColor:
                              materialUiTheme.palette.primary.light,
                            paddingBottom: materialUiTheme.spacing(4),
                            paddingTop: materialUiTheme.spacing(12),
                          }}
                        >
                          <S.TitleText>{title}</S.TitleText>
                          <Typography
                            style={{
                              color:
                                materialUiTheme.palette.primary.contrastText,
                              marginLeft: materialUiTheme.spacing(2),
                              marginRight: materialUiTheme.spacing(2),
                              textAlign: 'center',
                            }}
                          >
                            {description}
                          </Typography>
                        </Paper>
                      </Box>
                    </SlideAnimation>
                  </Grid>
                )
              }
            )}
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid
            container
            style={{
              marginBottom: materialUiTheme.spacing(10),
            }}
            spacing={4}
          >
            <Grid item xs={12}>
              <Flip>
                <SpecialtiesCarousel data={ourSpecialtiesData} />
              </Flip>
            </Grid>
          </Grid>
        </Hidden>
        <Fade top>
          <Box display="flex" justifyContent="center">
            <Fab
              color="secondary"
              size="large"
              variant="extended"
              onClick={() => navigate('/menu')}
            >
              SEE MORE
            </Fab>
          </Box>
        </Fade>
      </ContainedSection>
    </HomeLayout>
  )
}

const S = {
  MaEstellaFullBodyImageWrapper: styled(Box)`
    margin: auto;
    width: 148px;
  `,
  OurSpecialtiesSectionTitle: styled(Typography).attrs({
    variant: 'h4',
  })`
    font-family: ${fonts['Belmar-Normal']};
    font-size: ${materialUiTheme.typography.h5.fontSize};

    ${media.sm`
      font-size: ${materialUiTheme.typography.h4.fontSize};
    `}
  `,
  TitleText: styled(Typography).attrs({
    variant: 'h5',
  })`
    color: ${materialUiTheme.palette.primary.contrastText};
    font-family: ${fonts['Belmar-Normal']};
    font-size: ${materialUiTheme.typography.h6.fontSize};
    margin-bottom: ${materialUiTheme.spacing(3)}px;
    margin-left: ${materialUiTheme.spacing(1)}px;
    margin-right: ${materialUiTheme.spacing(1)}px;
    text-align: center;

    ${media.sm`
      font-size: ${materialUiTheme.typography.h5.fontSize};
    `}
  `,
}

const StyledHeroSliderOverlay = styled(Box)`
  background: rgba(0, 0, 0, 0.54);
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: -100;
`

const StyledSlideContentContainer = styled(Box)`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  flex-flow: column;
  height: 100%;
  justify-content: center;
  margin: 0;
  padding: 0;
  pointer-events: none;
  width: 100%;
  z-index: 100;
`

const StyledSlideContentTitle = styled(props => (
  <Typography variant="h2" {...props} />
))`
  color: #fff;
  font-family: ${fonts['Brush455BT']};
  font-size: ${materialUiTheme.typography.h3.fontSize};
  margin-top: ${materialUiTheme.spacing(8)}px;
  text-align: center;

  ${media.sm`
    font-size: ${materialUiTheme.typography.h2.fontSize};
  `}
`

const OurStoryHeading = styled(Typography).attrs({
  gutterBottom: true,
  variant: 'h4',
})`
  margin-bottom: ${materialUiTheme.spacing(2)}px;

  ${media.sm`
    margin-top: ${materialUiTheme.spacing(3)}px;
  `}

  ${media.md`
    margin-top: ${materialUiTheme.spacing(5)}px;
  `}
`

const StyledFloatingImageContainer = styled(Box)`
  margin-left: ${materialUiTheme.spacing(3)}px;
  max-width: 148px;

  ${media.sm`
    margin: auto;
    max-width: 276px;
  `};

  ${media.md`
    margin-right: -${materialUiTheme.spacing(18)}px;
  `}
`

const StyledSpecialMenuPaper = styled(Paper)`
  box-sizing: border-box;
  height: 100%;
  margin-top: -${materialUiTheme.spacing(4)}px;
  padding: ${materialUiTheme.spacing(5)}px ${materialUiTheme.spacing(3)}px
    ${materialUiTheme.spacing(5)}px;
  width: 100%;

  ${media.sm`
    margin-top: -${materialUiTheme.spacing(11)}px;
    padding: ${materialUiTheme.spacing(16)}px ${materialUiTheme.spacing(4)}px
    ${materialUiTheme.spacing(6)}px;
  `}

  ${media.md`
    margin-top: -${materialUiTheme.spacing(35)}px;

    padding: ${materialUiTheme.spacing(12)}px ${materialUiTheme.spacing(17)}px
    ${materialUiTheme.spacing(18)}px ${materialUiTheme.spacing(5)}px;
  `}
`

const StyledSpecialMenuContainer = styled(Box)`
  ${media.sm`
    padding-left: ${materialUiTheme.spacing(12)}px;
    padding-right: ${materialUiTheme.spacing(12)}px;
  `}

  ${media.md`
    padding-left: ${materialUiTheme.spacing(16)}px;
    padding-right: ${materialUiTheme.spacing(16)}px;
  `}
`

export default IndexPage
