/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'

import { ThemeProvider as StyledComponentThemeProvider } from 'styled-components'
import { ThemeProvider as MaterialUiThemeProvider } from '@material-ui/styles'

import { Box } from '@material-ui/core'

import styledComponentTheme from '../theme'
import materialUiTheme from '../material-ui-theme'

import Footer from './Footer'
import Header from './Header'

import './styles.css'

const HomeLayout = ({ children }) => (
  <StyledComponentThemeProvider theme={styledComponentTheme}>
    <MaterialUiThemeProvider theme={materialUiTheme}>
      <>
        <Header />
        <Box component="main">{children}</Box>
        <Footer showGetInTouchBanner />
      </>
    </MaterialUiThemeProvider>
  </StyledComponentThemeProvider>
)

HomeLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default HomeLayout
