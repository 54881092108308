import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

import { Box, IconButton, SvgIcon, Typography, Paper } from '@material-ui/core'
import Img from 'gatsby-image'
import Swiper from 'react-id-swiper'

import 'react-id-swiper/lib/styles/css/swiper.css'

import materialUiTheme, { fonts } from '../material-ui-theme'
import media from '../utils/media'

const SpecialtiesCarousel = props => {
  const { data } = props

  return (
    <Swiper
      spaceBetween={24}
      navigation={{
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }}
      renderNextButton={() => (
        <S.SwiperNavNextIconButton className="swiper-button-next">
          <SvgIcon>
            <path
              fill="currentColor"
              d="M16.452 12.852c.11-.114.195-.249.252-.396a1.2 1.2 0 0 0 0-.912 1.199 1.199 0 0 0-.252-.396l-3.6-3.6a1.205 1.205 0 0 0-1.704 1.704l1.56 1.548H8.4a1.2 1.2 0 1 0 0 2.4h4.308l-1.56 1.548a1.202 1.202 0 0 0 .39 1.967 1.2 1.2 0 0 0 1.314-.263l3.6-3.6zM24 12a12 12 0 1 0-24 0 12 12 0 0 0 24 0zM2.4 12a9.6 9.6 0 1 1 19.2 0 9.6 9.6 0 0 1-19.2 0z"
            />
          </SvgIcon>
        </S.SwiperNavNextIconButton>
      )}
      renderPrevButton={() => (
        <S.SwiperNavPrevIconButton className="swiper-button-prev">
          <SvgIcon>
            <path
              fill="currentColor"
              d="M7.548 11.148a1.2 1.2 0 0 0-.252.396 1.2 1.2 0 0 0 0 .912 1.2 1.2 0 0 0 .252.396l3.6 3.6a1.205 1.205 0 1 0 1.704-1.704l-1.56-1.548H15.6a1.2 1.2 0 1 0 0-2.4h-4.308l1.56-1.548a1.201 1.201 0 0 0 0-1.704 1.2 1.2 0 0 0-1.704 0l-3.6 3.6zM0 12a12 12 0 1 0 24 0 12 12 0 0 0-24 0zm21.6 0a9.6 9.6 0 1 1-19.2 0 9.6 9.6 0 0 1 19.2 0z"
            />
          </SvgIcon>
        </S.SwiperNavPrevIconButton>
      )}
    >
      {data.map(({ id, imageSrc, description, title }) => (
        <Box>
          <Box maxWidth="280px" mb={-11} mx="auto" key={id}>
            <Img fluid={imageSrc} />
          </Box>
          <Paper
            style={{
              backgroundColor: materialUiTheme.palette.primary.light,
              marginLeft: 'auto',
              marginRight: 'auto',
              maxWidth: '400px',
              paddingBottom: materialUiTheme.spacing(15),
              paddingTop: materialUiTheme.spacing(12),
            }}
          >
            <S.TitleText>{title}</S.TitleText>
            <Typography
              style={{
                color: materialUiTheme.palette.primary.contrastText,
                marginLeft: materialUiTheme.spacing(2),
                marginRight: materialUiTheme.spacing(2),
                textAlign: 'center',
              }}
            >
              {description}
            </Typography>
          </Paper>
        </Box>
      ))}
      {/**
       * ! FIXME: Not sure why using `FeaturedMenuCard` component
       * breaks when used inside `Swiper`.
       * I've duplicated <FeaturedMenuCard /> components/styles for the mean
       * time </3
       *
       * {data.map(d => (
       *   <FeaturedMenuCard key={d.id} data={d} />
       * ))}
       */}
    </Swiper>
  )
}

const S = {
  SwiperNavNextIconButton: styled(IconButton)`
    background-color: ${materialUiTheme.palette.common.white} !important;
    background-image: none !important;
    height: unset !important;
    width: unset !important;

    bottom: 10% !important;
    left: auto !important;
    right: 30% !important;
    top: auto !important;

    ${media.sm`
      bottom: 7% !important;
      right: 42% !important;
    `}
  `,
  SwiperNavPrevIconButton: styled(IconButton)`
    background-color: ${materialUiTheme.palette.common.white} !important;
    background-image: none !important;
    height: unset !important;
    width: unset !important;

    bottom: 10% !important;
    left: 30% !important;
    right: auto !important;
    top: auto !important;

    ${media.sm`
      bottom: 7% !important;
      left: 42% !important;
    `}
  `,
  TitleText: styled(Typography).attrs({
    variant: 'h5',
  })`
    color: ${materialUiTheme.palette.primary.contrastText};
    font-family: ${fonts['Belmar-Normal']};
    font-size: ${materialUiTheme.typography.h6.fontSize};
    margin-bottom: ${materialUiTheme.spacing(3)}px;
    margin-left: ${materialUiTheme.spacing(1)}px;
    margin-right: ${materialUiTheme.spacing(1)}px;
    text-align: center;

    ${media.sm`
      font-size: ${materialUiTheme.typography.h5.fontSize};
    `}
  `,
}

SpecialtiesCarousel.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      imageSrc: PropTypes.string.object,
    })
  ).isRequired,
}

export default SpecialtiesCarousel
